import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import DatePicker from "react-datepicker";
import tr from "date-fns/locale/tr";
import "react-datepicker/dist/react-datepicker.css"
import { eachYearOfInterval } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

class App extends Component {

  state = {
    username: "",
    password: "",
    Login: false,
    Token: "",
    IslemTuru: 1,
    DepartureStopId: 0,
    ArrivalStopId: 48041,
    SeferTarihi: new Date(),
    SeferSaati: 0,
    SeferDakikasi: 0,
    Yolcu: { Ad: '', Soyad: '', Telefon: '', Eposta: '', Servis: [] },
    YolcuListesi: [],
    ServisDuraklar: 0,
    KayitEdilen: 0,
    Shuttles: [],
    Saat: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    Dakika: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
    IlkTarih: new Date(),
    SonTarih: new Date()
  }

  handleInputChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: parseInt(e.target.value)
    });
  }

  kalkisduraklariGetir = () => {
    const ServisURL = "https://api.kamilkoc.com.tr/OBilet/Obilet/";
    const KalkisDuraklari = ServisURL + 'KalkisDuraklari';

    var config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Authorization': 'Bearer ' + this.state.TokenZz
      }
    };

    // var params = {
    //   TarSeferID: gelenBilet.TarSeferID,
    //   KalkisDurakID: gelenBilet.KalkisDurakID,
    //   VarisDurakID: gelenBilet.VarisDurakID
    // };

    axios.post(KalkisDuraklari, null, config).then(res => {
      if (res.data.Durum === 200) {
        this.setState({
          ...this.state,
          Duraklar: res.data.Duraklar
        });
      }
      else
        alert(res.data.ResponseMessage);
    }).catch(error => {
      if (error.response.status === 401) {
        alert("Lütfen giriş yapınız.");
        this.setState({
          ...this.state,
          Login: false,
          Token: "",
          username: "",
          password: ""
        });
      }
    })
  }

  servisGetir = () => {
    const ServisURL = "https://api.kamilkoc.com.tr/OBilet/OBilet/";
    const ShuttleList = ServisURL + 'ShuttleList';

    var config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Authorization': 'Bearer ' + this.state.Token
      }
    };


    var params = {
      DepartureStopId: this.state.DepartureStopId,
      ArrivalStopId: this.state.ArrivalStopId,
      DepartureDatetime: new Date(Date.UTC(this.state.SeferTarihi.getFullYear(), this.state.SeferTarihi.getMonth(), this.state.SeferTarihi.getDate(), this.state.SeferSaati, this.state.SeferDakikasi)),
      ArrivalDatetime: new Date()
    };



    axios.post(ShuttleList, params, config).then(res => {
      if (res.data.ResponseCode === 200) {
        if (res.data.DepartureShuttles.length > 0)
          this.setState({
            ...this.state,
            ServisDuraklar: res.data.DepartureShuttles,
            YolcuListesi: []
          });
        else {
          this.setState({
            ...this.state,
            ServisDuraklar: []
          });
          alert('Belirtilen kriterlere göre servis bulunamadı.');
        }
      }
      else
        alert(res.data.ResponseMessage);
    }).catch(error => {
      if (error.response.status === 401) {
        alert("Lütfen giriş yapınız.");
        this.setState({
          ...this.state,
          Login: false,
          Token: "",
          username: "",
          password: ""
        });
      }
    })
  }

  onSorguTipiChange = (e) => {
    this.setState({
      ...this.state,
      IslemTuru: parseInt(e.target.value),
      Yolcu: { Ad: '', Soyad: '', Telefon: '', Eposta: '', Servis: [] },
      YolcuListesi: [],
      ServisDuraklar: [],
      KayitEdilen: 0,
      Shuttles: []
    });
  }

  handleYolcuEkle = () => {
    if (!!this.state.Yolcu.Ad && !!this.state.Yolcu.Soyad && !!this.state.Yolcu.Servis.StopId) {
      this.setState({
        ...this.state,
        YolcuListesi: [...this.state.YolcuListesi, this.state.Yolcu],
        Yolcu: { Ad: '', Soyad: '', Telefon: '', Eposta: '', Servis: [] }
      });
      document.getElementById("ServisYeri").selectedIndex = 0;
    }
    else
      alert('Lütfen yolcu bilgilerini kontrol ediniz.')
  }

  handleYolcuSil = (xedni) => {
    this.setState({
      ...this.state,
      YolcuListesi: this.state.YolcuListesi.filter((kisi, index) => index !== xedni)
    });
  }

  handleYolcuVerisi = (e) => {
    var yolcu = this.state.Yolcu;
    if (e.target.name === 'Ad')
      yolcu.Ad = e.target.value;
    else if (e.target.name === 'Soyad')
      yolcu.Soyad = e.target.value;
    else if (e.target.name === 'Telefon')
      yolcu.Telefon = e.target.value;
    else if (e.target.name === 'Eposta')
      yolcu.Eposta = e.target.value;
    else {
      var servis = this.state.ServisDuraklar.find(servis => { return servis.StopId == e.target.value })
      yolcu.Servis = servis;
      // yolcu.ServisYeri = servis.StopName + '-' + servis.ShuttleTime.split('T')[1];
    }
    this.setState({
      ...this.state,
      Yolcu: yolcu
    });
  }

  handleYolcuVerisiGuncelle = (e, index) => {
    var yolcu = this.state.Shuttles;
    var servis = this.state.ServisDuraklar.find(servis => { return servis.StopId == e.target.value })
    yolcu[index].StopId = servis.StopId;
    yolcu[index].ShuttleTime = servis.ShuttleTime;
    this.setState({
      ...this.state,
      Shuttles: yolcu
    });
  }

  handleServisKaydet = () => {
    const ServisURL = "https://api.kamilkoc.com.tr/OBilet/OBilet/";
    const SaveShuttle = ServisURL + 'SaveShuttle';

    var config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + this.state.Token
      }
    };

    const length = this.state.YolcuListesi.length

    this.state.YolcuListesi.map((yolcu, index) => {

      var params = {
        BookingId: this.state.username === 'OBILET' ? uuidv4() : '',
        PassengerName: yolcu.Ad,
        PassengerSurname: yolcu.Soyad,
        PassengerPhone: yolcu.Telefon,
        PassengerEmail: yolcu.Eposta,
        StopId: yolcu.Servis.StopId,
        StopType: '1',
        ShuttleTime: yolcu.Servis.ShuttleTime,
        ChannelId: '3'
      };

      axios.post(SaveShuttle, params, config).then(res => {
        if (res.data.ResponseCode === 200) {
          this.setState({
            ...this.state,
            KayitEdilen: this.state.KayitEdilen + 1
          }, () => {
            if (index == length - 1) {
              alert(this.state.KayitEdilen + ' yolcu kayit edildi.')
              this.setState({
                ...this.state,
                ServisDuraklar: [],
                YolcuListesi: [],
                Yolcu: { Ad: '', Soyad: '', Telefon: '', Eposta: '', Servis: [] }
              });
            }
          });
        }
        else
          alert(res.data.ResponseMessage);
      }).catch(error => {
        if (error.response.status === 401) {
          alert("Lütfen giriş yapınız.");
          this.setState({
            ...this.state,
            Login: false,
            Token: "",
            username: "",
            password: ""
          });
        }
      })

    });

  }


  handleRapor = () => {
    const ServisURL = "https://api.kamilkoc.com.tr/OBilet/Obilet/";
    const Rapor = ServisURL + 'Rapor';

    const headers = {
      'Authorization': 'Bearer ' + this.state.Token,
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    var params = {
      RaporTipi: 1,
      IlkTarih: this.state.IlkTarih.getFullYear() + '-' + (this.state.IlkTarih.getMonth() + 1) + '-' + this.state.IlkTarih.getDate(),
      SonTarih: this.state.SonTarih.getFullYear() + '-' + (this.state.SonTarih.getMonth() + 1) + '-' + this.state.SonTarih.getDate()
    };


    axios.post(Rapor, params, { responseType: 'blob', headers: headers }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'KamilKocRapor.csv');
      document.body.appendChild(link);
      link.click();
    });


  }


  handleServisGuncelle = () => {
    const ServisURL = "https://api.kamilkoc.com.tr/OBilet/OBilet/";
    const SaveShuttle = ServisURL + 'SaveShuttle';

    var config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + this.state.Token
      }
    };

    const length = this.state.Shuttles.length

    this.state.Shuttles.map((yolcu, index) => {

      var params = {
        BookingId: yolcu.BookingId,
        PassengerName: yolcu.PassengerName,
        PassengerSurname: yolcu.PassengerSurname,
        PassengerPhone: yolcu.Phone,
        PassengerEmail: yolcu.Email,
        StopId: yolcu.StopId,
        StopType: '1',
        ShuttleTime: yolcu.ShuttleTime,
        ChannelId: '3'
      };

      axios.post(SaveShuttle, params, config).then(res => {
        if (res.data.ResponseCode === 200) {
          this.setState({
            ...this.state,
            KayitEdilen: this.state.KayitEdilen + 1
          }, () => {
            if (index == length - 1) {
              alert(this.state.KayitEdilen + ' yolcu güncellendi.')
              this.setState({
                ...this.state,
                ServisDuraklar: [],
                Shuttles: [],
                Yolcu: { Ad: '', Soyad: '', Telefon: '', Eposta: '', Servis: [] },
              });
            }
          });
        }
        else
          alert(res.data.ResponseMessage);
      }).catch(error => {
        if (error.response.status === 401) {
          alert("Lütfen giriş yapınız.");
          this.setState({
            ...this.state,
            Login: false,
            Token: "",
            username: "",
            password: ""
          });
        }
      })

    });

  }

  handleYolcuAra = () => {
    const ServisURL = "https://api.kamilkoc.com.tr/OBilet/OBilet/";
    const FindShuttle = ServisURL + 'FindShuttle';

    var config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + this.state.Token
      }
    };


    var params = {
      Name: this.state.Yolcu.Ad,
      Surname: this.state.Yolcu.Soyad,
      GSM: this.state.Yolcu.Telefon,
      DepartureStopId: this.state.DepartureStopId,
      // ArrivalStopId: this.state.ArrivalStopId,
      DepartureDatetime: new Date(Date.UTC(this.state.SeferTarihi.getFullYear(), this.state.SeferTarihi.getMonth(), this.state.SeferTarihi.getDate(), this.state.SeferSaati, this.state.SeferDakikasi)),
      // ArrivalDatetime: new Date()      
    };


    if (!(!!params.DepartureStopId && !!params.DepartureDatetime && ((!!params.Name && !!params.Surname) || !!params.GSM))) {
      alert('Kalkış yeri ve tarihi ile birlikte Ad-Soyad veya Telefon girilmesi gerekmektedir.');
      return;
    }

    axios.post(FindShuttle, params, config).then(res => {
      if (res.data.ResponseCode === 200) {
        this.setState({
          ...this.state,
          Shuttles: res.data.Shuttles
        }, () => {
          if (this.state.Shuttles.length < 1) {
            alert('Servis yolcusu bulunamadı.')
          }
        });
        this.servisGetir();
      }
      else
        alert(res.data.ResponseMessage);
    }).catch(error => {
      if (error.response.status === 401) {
        alert("Lütfen giriş yapınız.");
        this.setState({
          ...this.state,
          Login: false,
          Token: "",
          username: "",
          password: ""
        });
      }
    })
  }


  loginChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.name === 'username' ? e.target.value.toUpperCase() : e.target.value
    });
  }

  loginCheck = () => {
    if (!this.state.username || !this.state.password)
      alert("Kullanıcı adı veya Şifre boş olamaz!")
    else {
      const ServisURL = "https://api.kamilkoc.com.tr/OBilet/Obilet/";

      const LoginKontrol = ServisURL + 'LoginKontrol';

      var config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };
      var params = {
        ID: this.state.username.toUpperCase(),
        Sifre: this.state.password,
        DuzDurakID: 1394
      };
      axios.post(LoginKontrol, params, config).then(res => {
        if (res.data.Durum === 200) {
          this.setState({
            ...this.state,
            Login: true,
            Token: res.data.Token,
            password: ""
          });
          this.kalkisduraklariGetir();
        }
        else
          alert(res.data.ResponseMessage);
      })

    }
  }

  handleTarihChange = (param, e) => {
    this.setState({
      ...this.state,
      [param]: e
    });
  }

  render() {
    return (
      this.state.Login && this.state.Token ?
        (this.state.username.toUpperCase() !== "OBILETRAPOR" ?
          <div className="container" >
            <div className="row">
              <div className="col-md-12">
                <input type="radio" value="1" className="w15h15" name="IslemTuru" onChange={this.onSorguTipiChange} defaultChecked /> <label style={{ marginRight: '10px' }}>Yeni Servis Ekle</label><br></br>
                <input type="radio" value="2" className="w15h15" name="IslemTuru" onChange={this.onSorguTipiChange} /> <label>Mevcut Servisi Değiştir</label>
              </div>
            </div>
            <hr></hr>

            <div className="row">
              <div className="col-md-3">
                Kalkış Yeri
                </div>
              <div className="col-md-3">
                Sefer Tarihi
                </div>
              <div className="col-md-3">
                Sefer Saati
                </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <select className="w100 h100" name="DepartureStopId" onChange={this.handleInputChange}>
                  <option key={-1} value={-1}>KALKIŞ NOKTASI SEÇİNİZ</option>
                  {
                    !!this.state.Duraklar && this.state.Duraklar.map(durak => {
                      return <option key={durak.FBDurakID} value={durak.FBDurakID}>{durak.DurakAdi}</option>
                    })
                  }
                </select>
              </div>
              <div className="col-md-3">
                <DatePicker dateFormat="dd.MM.yyyy"
                  className="form-control mb10 w100 h100"
                  label="Sefer Kalkış Tarihi"
                  locale={tr}
                  selected={this.state.SeferTarihi}
                  name="IlkTarih"
                  onChange={(e) => this.handleTarihChange("SeferTarihi", e)}>
                </DatePicker>
              </div>
              <div className="col-md-3">
                <select name="SeferSaati" className="w50 h100" onChange={this.handleInputChange}>
                  {
                    this.state.Saat.map(saat => {
                      return <option key={saat} value={saat}>{saat}</option>
                    })
                  }
                </select>

                <select name="SeferDakikasi" className="w50 h100" onChange={this.handleInputChange}>
                  {
                    this.state.Dakika.map(dakika => {
                      return <option key={dakika} value={dakika}>{dakika}</option>
                    })
                  }
                </select>
              </div>
              {
                this.state.IslemTuru === 1 ?
                  <div className="col-md-1">
                    <input type="submit" className="h100" value="Servis Getir" onClick={this.servisGetir} />
                  </div>
                  :
                  null
              }
            </div>
            {
              this.state.IslemTuru === 2 ?
                <div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-3">
                      <input type="text" placeholder="Ad" className="w100 h100" name={'Ad'} value={this.state.Yolcu.Ad} onChange={this.handleYolcuVerisi} />
                    </div>
                    <div className="col-md-3">
                      <input type="text" placeholder="Soyad" className="w100 h100" name={'Soyad'} value={this.state.Yolcu.Soyad} onChange={this.handleYolcuVerisi} />
                    </div>
                    <div className="col-md-3">
                      <input type="text" placeholder="Telefon" className="w100 h100" name={'Telefon'} value={this.state.Yolcu.Telefon} onChange={this.handleYolcuVerisi} />
                    </div>
                    <div className="col-md-3">
                      <button className="w100 h100" type="button" onClick={this.handleYolcuAra}>Yolcu Ara</button>
                    </div>
                  </div>
                </div>
                :
                null
            }



            <hr></hr>

            {
              this.state.IslemTuru === 1 && this.state.ServisDuraklar.length > 1 &&
              <div className="row" id={'yolcu'}>
                <div className="col-md-2">
                  <input type="text" placeholder="Ad" className="w100 h100" name={'Ad'} value={this.state.Yolcu.Ad} onChange={this.handleYolcuVerisi} />
                </div>
                <div className="col-md-2">
                  <input type="text" placeholder="Soyad" className="w100 h100" name={'Soyad'} value={this.state.Yolcu.Soyad} onChange={this.handleYolcuVerisi}></input>
                </div>
                <div className="col-md-2">
                  <input type="text" placeholder="Telefon" className="w100 h100" name={'Telefon'} value={this.state.Yolcu.Telefon} onChange={this.handleYolcuVerisi}></input>
                </div>
                <div className="col-md-2">
                  <input type="text" placeholder="Eposta" className="w100 h100" name={'Eposta'} value={this.state.Yolcu.Eposta} onChange={this.handleYolcuVerisi}></input>
                </div>
                <div className="col-md-2">
                  <select className="w100 h100" id="ServisYeri" name="ServisYeri" onChange={this.handleYolcuVerisi} value={!!this.state.Yolcu.Servis ? this.state.Yolcu.Servis.StopId : -1}>
                    <option key="-1" value="-1">Servis Seçiniz</option>
                    {
                      this.state.ServisDuraklar.map(durak => {
                        return <option key={durak.StopId} value={durak.StopId}>{durak.StopName + '-' + durak.ShuttleTime.split('T')[1]}</option>
                      })
                    }
                  </select>
                </div>
                <div className="col-md-2">
                  <button className="w100 h100" type="button" onClick={this.handleYolcuEkle}>Yolcu Ekle</button>
                </div>
              </div>
            }

            <br></br><br></br>

            {
              this.state.IslemTuru === 1 && !!this.state.YolcuListesi && this.state.YolcuListesi.length >= 1 &&
              <div>
                <div className="row center">
                  <div className="col-md-2">
                    <label>Ad</label>
                  </div>
                  <div className="col-md-2">
                    <label>Soyad</label>
                  </div>
                  <div className="col-md-2">
                    <label>Telefon</label>
                  </div>
                  <div className="col-md-2">
                    <label>Eposta</label>
                  </div>
                  <div className="col-md-2">
                    <label>Servis Yeri</label>
                  </div>
                </div>
                {this.state.YolcuListesi.map((kisi, index) =>
                  <div className="row" id={'yolcu' + index} key={index}>
                    <div className="col-md-2">
                      <label className="w100 h100">{kisi.Ad}</label>
                    </div>
                    <div className="col-md-2">
                      <label className="w100 h100">{kisi.Soyad}</label>
                    </div>
                    <div className="col-md-2">
                      <label className="w100 h100">{kisi.Telefon}</label>
                    </div>
                    <div className="col-md-2">
                      <label className="w100 h100">{kisi.Eposta}</label>
                    </div>
                    <div className="col-md-2">
                      <label className="w100 h100">{kisi.Servis.StopName + '-' + kisi.Servis.ShuttleTime.split('T')[1]}</label>
                    </div>
                    <div className="col-md-2">
                      <button className="w100 h100" type="button" onClick={() => this.handleYolcuSil(index)}>Sil</button>
                    </div>
                  </div>
                )}
                <br></br>
                <button type="button" onClick={this.handleServisKaydet}>Servis Kaydet</button>
              </div>


            }

            {
              !!this.state.Shuttles && this.state.Shuttles.length >= 1 &&
              <div>
                <div className="row center">
                  <div className="col-md-2">
                    <label>Ad</label>
                  </div>
                  <div className="col-md-2">
                    <label>Soyad</label>
                  </div>
                  <div className="col-md-2">
                    <label>Telefon</label>
                  </div>
                  <div className="col-md-2">
                    <label>Eposta</label>
                  </div>
                  <div className="col-md-2">
                    <label>Servis Yeri</label>
                  </div>
                </div>
                {this.state.Shuttles.map((kisi, index) =>
                  <div className="row" id={'yolcu' + index} key={index}>
                    <div className="col-md-2">
                      <label className="w100 h100">{kisi.PassengerName}</label>
                    </div>
                    <div className="col-md-2">
                      <label className="w100 h100">{kisi.PassengerSurname}</label>
                    </div>
                    <div className="col-md-2">
                      <label className="w100 h100">{kisi.Phone}</label>
                    </div>
                    <div className="col-md-2">
                      <label className="w100 h100">{kisi.Email}</label>
                    </div>
                    <div className="col-md-2">
                      {/* <label className="w100 h100">{kisi.Servis.StopName + '-' + kisi.Servis.ShuttleTime.split('T')[1]}</label> */}
                      <select className="w100 h100" id="ServisYeri" name="ServisYeri" onChange={(e) => this.handleYolcuVerisiGuncelle(e, index)}>
                        <option key="-1" value="-1">Servis Seçiniz</option>
                        {
                          this.state.ServisDuraklar.map(durak => {
                            return <option key={durak.StopId} selected={parseInt(durak.StopId) === parseInt(kisi.StopId) ? true : false} value={durak.StopId}>{durak.StopName + '-' + durak.ShuttleTime.split('T')[1]}</option>
                          })
                        }
                      </select>
                    </div>
                    <div className="col-md-2">
                    </div>
                  </div>
                )}
                <br></br>
                <button type="button" onClick={this.handleServisGuncelle}>Servis Güncelle</button>
              </div>
            }


          </div >
          :
          <div className="container" >
            <div className="row">
              <div className="col-md-1">
                İlk Tarih
              </div>
              <div className="col-md-11">
                <DatePicker dateFormat="dd.MM.yyyy" locale="tr"
                  className="form-control mb10" selected={this.state.IlkTarih}
                  label="İlk Tarih"
                  locale={tr}
                  name="IlkTarih"
                  onChange={(e) => this.handleTarihChange("IlkTarih", e)}>
                </DatePicker>
              </div>
            </div>


            <div className="row">
              <div className="col-md-1">
                Son Tarih
              </div>
              <div className="col-md-11">
                <DatePicker dateFormat="dd.MM.yyyy" locale="tr"
                  className="form-control mb10" selected={this.state.SonTarih}
                  label="Son Tarih"
                  locale={tr}
                  name="SonTarih"
                  onChange={(e) => this.handleTarihChange("SonTarih", e)}>
                </DatePicker>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <input type="submit" value="Raporu İndir" onClick={this.handleRapor} />
              </div>
            </div>
          </div>
        )

        :
        //LOGIN
        <div className="container">
          <br></br>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <input type="text" id="username" className="fadeIn second" name="username" placeholder="Kullanıcı Adı" onChange={this.loginChange} />
            </div>
            <div className="col-md-4"></div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <input type="password" id="password" className="fadeIn third" name="password" placeholder="Şifre" onChange={this.loginChange} />
            </div>
            <div className="col-md-4"></div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <input type="submit" className="fadeIn fourth" value="Giriş" onClick={this.loginCheck} />
              {/* <button onClick={this.loginCheck} type="submit">Giriş</button> */}
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      //LOGIN END
    );
  }
}

export default App;